











import { APP_META } from '@/consts'
import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
export default Vue.extend({
	name: 'InfoPage',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `InfoPage | ${APP_META.title}`,
		}
	},
	data: () => ({
		rawInfoContent: '',
	}),
	created() {
		fetch(`/info/${this.id}.htm`)
			.then((res) => {
				return res.text()
			})
			.then((data) => {
				data = data.replace(/\.html?/g, '').trim()
				// .replaceAll('<a href="/info', '<a target="_blank" href="/info')
				// .replaceAll('<a href="/info', '<router-link to="/info')
				// .replaceAll('</a>', '</router-link>')
				this.rawInfoContent = data
				this.$nextTick(() => {
					const ref = this.$refs.ref as Element
					const name = ['h1', 'h2']
						.map((tag) => {
							return ref.querySelector(tag)?.innerHTML
						})
						.find((v) => v)

					if (!name) return
					this.$syncStack({
						name,
						path: this.$route.path,
					})
				})
			})
	},
	computed: {
		id(): string {
			return this.$route.params.id
		},
	},
})
